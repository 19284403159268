var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accordion-wrapper",style:({ flexDirection: _vm.isReversed ? 'column-reverse' : 'column' })},[_c('div',{staticClass:"accordion-row",class:[{ open: _vm.isOpen }, _vm.variant],attrs:{"aria-expanded":_vm.isOpen,"aria-controls":("collapse" + _vm._uid)},on:{"click":function($event){return _vm.toggleAccordion()}}},[(!_vm.hasTitleOpenSlot && !_vm.hasTitleClosedSlot)?_vm._t("title"):(_vm.isOpen)?_vm._t("title-open"):_vm._t("title-closed"),_vm._v(" "),(!_vm.hideChevron)?[(
          (_vm.variant === 'secondary' && _vm.isOpen) ||
          (_vm.variant === 'primary' && !_vm.isOpen) ||
          (_vm.variant === 'no-border' && !_vm.isOpen)
        )?_c('IconsChevronDownIcon24px'):_vm._e(),_vm._v(" "),(
          (_vm.variant === 'secondary' && !_vm.isOpen) ||
          (_vm.variant === 'primary' && _vm.isOpen) ||
          (_vm.variant === 'no-border' && _vm.isOpen)
        )?_c('IconsChevronUpIcon24px'):_vm._e()]:_vm._e(),_vm._v(" "),_vm._t("title_detail")],2),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"accordion-content",class:{ open: _vm.isOpen, 'vertical-content': _vm.verticalContent },attrs:{"id":("collapse" + _vm._uid)}},[_vm._t("content")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }