
































import {
  computed,
  onServerPrefetch,
  defineComponent
} from '@nuxtjs/composition-api';
import { useFooter } from '@vue-storefront/novulo';
import useWindowResize from '~/hooks/useResizeWindow';
import useCurrentPage from '~/hooks/useCurrentPage';

export default defineComponent({
  name: 'KEFooter',
  setup() {
    const { getFooter, footerResult } = useFooter();
    const { isDesktop } = useWindowResize();
    const { isHomePage } = useCurrentPage();

    onServerPrefetch(async () => {
      await getFooter();
    });

    const uspsList = computed(() =>
      footerResult.value ? Object.values(footerResult.value.footer_usps) : []
    );

    const hasMegaList = computed(() =>
      isHomePage.value && footerResult.value
        ? footerResult.value?.footer_mega_list?.length
        : false
    );

    return {
      uspsList,
      isDesktop,
      hasMegaList
    };
  }
});
