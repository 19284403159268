













































































































import {
  defineComponent,
  computed,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { SfDropdown } from '@storefront-ui/vue';
import { useLanguageSelection, useTranslations } from '@vue-storefront/novulo';
import { extractPathFromUrl } from '~/helpers/utils';
import { useVSFContext } from '@vue-storefront/core';

// Import flag images
// @ts-ignore
import luxembourgFlag from '@/assets/images/luxembourg-flag.png';
// @ts-ignore
import belgiumFlag from '@/assets/images/belgium-flag.png';
// @ts-ignore
import netherlandsFlag from '@/assets/images/netherlands-flag.png';

export default defineComponent({
  name: 'LanguageSelector',
  components: {
    SfDropdown
  },
  props: {
    isSmall: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { translate } = useTranslations();
    const isOpen = ref(false);
    const showLanguages = ref(false);
    const { i18n, $config, route } = useContext();
    const languages = Object.keys($config.domainConfig.lang);
    const selectedLanguageValue = i18n.locale.toUpperCase();
    const vsfContext = useVSFContext();
    // 40 43 7 20 are for belgium, 25 24 are for luxembourg 11 54 41 42 for netherlands
    const wt = vsfContext.$novulo.config.state.getWt();
    const selectedLanguageFlag = computed(() => {
      if ([40, 43, 7, 20].includes(wt)) {
        return belgiumFlag;
      } else if ([25, 24].includes(wt)) {
        return luxembourgFlag;
      } else if ([11, 54, 41, 42].includes(wt)) {
        return netherlandsFlag;
      } else {
        return null;
      }
    });
    const { getSlugRedirects } = useLanguageSelection();

    const handleToggle = () => {
      isOpen.value = !isOpen.value;
    };

    const handleClose = () => {
      isOpen.value = false;
    };

    const mappedListOfLanguages = computed(() =>
      Object.values(i18n.locales)
        .filter((lang) => languages.includes(lang.iso))
        .map((lang) => ({
          ...lang,
          code: lang.iso.toLowerCase()
        }))
    );

    const handleLanguageChange = async (languageValue: string) => {
      showLanguages.value = false;
      isOpen.value = false;
      const pathName = extractPathFromUrl(route.value.path);
      const currentHost = window.location.host;
      const slugRedirects = await getSlugRedirects(pathName, currentHost);
      const redirectionUrl = slugRedirects?.[languageValue.toLowerCase()];
      if (!redirectionUrl) return;
      const queryParams = window.location.search;
      window.location.href = redirectionUrl + queryParams;
    };

    return {
      isOpen,
      handleToggle,
      handleClose,
      showLanguages,
      mappedListOfLanguages,
      languages,
      selectedLanguageValue,
      handleLanguageChange,
      selectedLanguageFlag,
      translate
    };
  }
});
