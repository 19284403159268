import { render, staticRenderFns } from "./Copyright.vue?vue&type=template&id=1c5f2679&scoped=true&"
import script from "./Copyright.vue?vue&type=script&lang=ts&"
export * from "./Copyright.vue?vue&type=script&lang=ts&"
import style0 from "./Copyright.vue?vue&type=style&index=0&id=1c5f2679&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c5f2679",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesFooterFootnoteLinks: require('/var/www/packages/theme/components/molecules/FooterFootnoteLinks.vue').default})
