var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sf-button',_vm._g(_vm._b({staticClass:"sf-button--outline",class:{
    circle: _vm.variant === 'circle',
    'full-width': _vm.fullWidth,
    'dark-mode': _vm.darkMode
  },style:({
    '--button-width': _vm.variant === 'circle' ? _vm.circleSize : '',
    '--button-height': _vm.variant === 'circle' ? _vm.circleSize : ''
  })},'sf-button',_vm.$attrs,false),_vm.$listeners),[_vm._t("icon"),_vm._v(" "),(_vm.variant === 'rectangle')?_vm._t("default"):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }