
















































































































































































































































































import {
  defineComponent,
  ref,
  watch,
  computed,
  nextTick
} from '@nuxtjs/composition-api';
import { useHeader, useMegaMenu, useKECart } from '@vue-storefront/novulo';
import { onSSR, useVSFContext } from '@vue-storefront/core';
import { SfLink } from '@storefront-ui/vue';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { mediaUrlFallback } from '@/helpers/utils';
import useUrlFormatter from '~/hooks/useUrlFormatter';
import useDefaultHeaderToggle from '~/hooks/useDefaultHeaderToggle';

export default defineComponent({
  name: 'KEHeader',
  components: {
    SfLink
  },
  setup() {
    const { shouldHideHeader } = useDefaultHeaderToggle();
    const isMobileMenuOpen = ref(false);
    const tabActive = ref('');
    const isOpen = ref(false);
    const headerRef = ref();
    const { getHeader, headerResult } = useHeader();
    const { getMegaMenu, megaMenuResult } = useMegaMenu();
    const { isOpen: isCartDropdownOpen, setIsCartOpen } = useKECart();
    const formatUrl = useUrlFormatter();
    const { $novulo } = useVSFContext();
    const salesChannel = $novulo.config.state.getSalesChannel();

    const srcLogo = computed(() => {
      if (!headerResult.value)
        return {
          media_path: '',
          media_file_name: ''
        };
      const logoMedia = headerResult.value.find(
        (d) => d.code === 'header_country_logo'
      )?.elements?.[0]?.media?.[0];
      return {
        media_path: mediaUrlFallback(logoMedia?.media_path),
        media_file_name: logoMedia?.media_file_name
      };
    });

    const hasBottomLogo = computed(() => {
      return (
        headerResult?.value?.find((d) => d.code === 'header_country_logo')
          ?.elements?.[0]?.ke_logo === false
      );
    });

    // get all different tabs from the megamenu items' parentCategory
    const tabs = computed(() => {
      if (!Array.isArray(megaMenuResult.value)) return [];
      // tabs come from the api unordered, so we need to order them according to this array
      const parentIdOrder = ['Producten', 'Merken', 'Service', 'Inspiratie'];
      const mappedMegamenu = megaMenuResult.value.map((item) => ({
        parentId: item.parentId,
        mainDesc: item.mainDesc
      }));
      const availableTabs = new Set(
        mappedMegamenu
          .filter(
            (item) => parentIdOrder.includes(item.parentId) && item.mainDesc
          )
          .sort(
            (a, b) =>
              parentIdOrder.indexOf(a.parentId) -
              parentIdOrder.indexOf(b.parentId)
          )
          .map((item) => item.parentId)
      );

      // Keep inspiration tab only on specific sales channels
      const channelsWithInspiration = [10, 12, 57, 79, 69].includes(
        salesChannel
      );
      if (!channelsWithInspiration) availableTabs.delete('Inspiratie');

      return Array.from(availableTabs);
    });

    const extraLinkTabs = computed(() => {
      return (
        headerResult.value.find((d) => d.code === 'header_mega_menu_buttons')
          ?.elements?.[0]?.urls || []
      );
    });
    const getCategories = computed(() => {
      const categories = {};
      if (!Array.isArray(megaMenuResult.value)) return {};
      megaMenuResult.value.forEach((element) => {
        if (!categories.hasOwnProperty(element.parentId)) {
          categories[element.parentId] = [element];
        } else {
          categories[element.parentId].push(element);
        }
      });
      return categories;
    });

    const spotlightBarData = computed(() => {
      return headerResult.value?.[0]?.spotlight_bar?.[0]?.content || null;
    });

    const homeLink = formatUrl('/');

    const uspsGetter = computed(() => {
      if (!Array.isArray(headerResult.value)) return [];
      return headerResult.value.find((d) => d.code === 'header_usps')
        ?.elements?.[0]?.urls;
    });

    const quickLinksGetter = (position: string) => {
      if (!Array.isArray(headerResult.value)) return [];

      /* from backend
       * top: between 10 and 19 (inclusive)
       * navigation: between 20 and 29 (inclusive)
       */
      const positionFilters = {
        navigation: (item) => item.position >= 20 && item.position < 30,
        top: (item) => item.position >= 10 && item.position < 20
      };

      const positionFilter = positionFilters[position] || positionFilters.top;
      const links =
        headerResult.value
          .find((d) => d.code === 'header_basic_links')
          ?.elements?.[0]?.urls?.filter(positionFilter) || [];
      return links.sort((a, b) => a.position - b.position);
    };

    onSSR(async () => {
      await getMegaMenu();
      await getHeader();
    });

    watch(
      () => isMobileMenuOpen.value,
      (value) => {
        const body = document.querySelector('body');
        if (value) {
          if (body) {
            nextTick(() => {
              document.body.classList.add('scroll-lock');
              disableBodyScroll(headerRef.value);
            });
          }
        } else {
          body.classList.remove('scroll-lock');
          clearAllBodyScrollLocks();
        }
      }
    );

    // dropdowns
    const isLoginDropdownOpen = ref(false);
    const handleToggleLoginDropdown = (newValue = null) => {
      if (newValue === null) {
        isLoginDropdownOpen.value = !isLoginDropdownOpen.value;
      } else {
        isLoginDropdownOpen.value = newValue;
      }
      if (isLoginDropdownOpen.value) {
        setIsCartOpen(false);
      }
    };

    const handleToggleCartDropdown = (newValue = null) => {
      if (newValue === null) {
        setIsCartOpen(!isCartDropdownOpen.value);
      } else {
        setIsCartOpen(newValue);
      }
      if (isCartDropdownOpen.value) {
        isLoginDropdownOpen.value = false;
      }
    };

    return {
      isMobileMenuOpen,
      tabActive,
      isOpen,
      headerResult,
      megaMenuResult,
      tabs,
      extraLinkTabs,
      homeLink,
      srcLogo,
      hasBottomLogo,
      spotlightBarData,
      QUICK_LINKS_TYPE: {
        navigation: 'navigation',
        top: 'top'
      },
      getCategories,
      shouldHideHeader,
      uspsGetter,
      quickLinksGetter,
      formatUrl,
      isLoginDropdownOpen,
      isCartDropdownOpen,
      handleToggleLoginDropdown,
      handleToggleCartDropdown
    };
  }
});
