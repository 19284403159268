import { render, staticRenderFns } from "./MegaMenuLevelTwo.vue?vue&type=template&id=4fc135c6&scoped=true&"
import script from "./MegaMenuLevelTwo.vue?vue&type=script&lang=ts&"
export * from "./MegaMenuLevelTwo.vue?vue&type=script&lang=ts&"
import style0 from "./MegaMenuLevelTwo.vue?vue&type=style&index=0&id=4fc135c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fc135c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsThumbnail: require('/var/www/packages/theme/components/atoms/Thumbnail.vue').default,AtomsPromo: require('/var/www/packages/theme/components/atoms/Promo.vue').default})
