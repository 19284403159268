




















































































import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';

import { useFooter, useTranslations } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'FooterTopCategories',
  setup() {
    const { isDesktop } = useWindowResize();
    const { footerResult } = useFooter();
    const { translate } = useTranslations();

    const openAccordionId = ref<number | null>(null);
    const toggleAccordion = (id: number) => {
      openAccordionId.value = openAccordionId.value === id ? null : id;
    };

    const footerMegaList = Array.isArray(footerResult.value?.footer_mega_list)
      ? footerResult.value.footer_mega_list
      : [];

    const topCategories = computed(() =>
      footerMegaList
        .filter((element) => element.code !== 'text' && element.urls?.length)
        .slice(0, 8)
        .map((list) => ({
          ...list,
          urls: [
            ...list.urls,
            { ...list.urls[0], title: translate('topcategories_viewmore') }
          ]
        }))
    );
    const textElements = computed(() => {
      return footerMegaList.reduce(
        (acc, { code, markdown_content }) => {
          const normalizedCode = code?.toLowerCase();
          if (normalizedCode === 'title' || normalizedCode === 'subtitle') {
            acc[normalizedCode] = markdown_content || '';
          }
          return acc;
        },
        { title: '', subtitle: '' }
      );
    });

    return {
      translate,
      isDesktop,
      topCategories,
      openAccordionId,
      toggleAccordion,
      topCategoriesTitle: computed(() => textElements.value.title),
      topCategoriesSubTitle: computed(() => textElements.value.subtitle),
      mega: footerResult.value.footer_mega_list
    };
  }
});
