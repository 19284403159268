











import { defineComponent } from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';
import { useTranslations } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'Copyright',
  setup() {
    const { isDesktop } = useWindowResize();
    const { translate } = useTranslations();

    return {
      currentYear: new Date().getFullYear().toString(),
      translate,
      isDesktop
    };
  }
});
