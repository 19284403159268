




















































































import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { SfInput, SfIcon, SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'InputField',
  components: {
    SfInput,
    SfIcon,
    SfButton
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Form Input'
    },
    isValid: {
      type: Boolean,
      default: false
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Error message value of form input.'
    },
    hasInfo: {
      type: Boolean,
      default: false
    },
    hasShowPassword: {
      type: Boolean,
      default: false
    },
    hasCheckmark: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const vsfContext = useVSFContext();

    const inputType = ref(props.type);

    const isPasswordVisible = computed(() => inputType.value !== 'password');

    // Enable this functionality only on NL, BE and DE
    const isFieldRequiredAsteriskEnabled = computed(() => {
      const wt = vsfContext.$novulo.config.state.getWt();
      return [11, 7, 20, 21, 30, 28, 40, 43, 35, 53, 54, 57, 60, 61].includes(
        wt
      );
    });

    const displayedLabel = computed(() => {
      let result = props.label;

      if (props.isRequired && isFieldRequiredAsteriskEnabled.value) {
        result += '*';
      }

      return result;
    });

    return {
      handleChange: (newValue) => {
        emit('input', newValue);
      },
      switchVisibilityPassword() {
        if (props.type !== 'password') return;
        inputType.value = isPasswordVisible.value ? 'password' : 'text';
      },
      inputType,
      isPasswordVisible,
      displayedLabel
    };
  }
});
