




















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

type SocialContactButtonTypes = 'rectangle' | 'circle';

export default defineComponent({
  name: 'SocialContactButton',
  components: {
    SfButton
  },
  props: {
    variant: {
      type: String as PropType<SocialContactButtonTypes>,
      default: 'rectangle'
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    darkMode: {
      type: Boolean,
      default: false
    },
    circleSize: {
      type: String,
      default: '40px'
    }
  }
});
