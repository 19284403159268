import { ref, computed, onMounted, useContext } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';

const MARKETING_CONSENT_COOKIE = 'cookie_accepted';
const ANALYTICS_CONSENT_COOKIE = 'analytics_cookie_accepted';
const NOVULO_TRACKING_COOKIE = 'start_br';

export default function useMarketingTracking() {
  const context = useContext();
  const { $cookies } = useVSFContext();

  const urlInfo = computed(() => context.store.state.page.urlInfo);

  const isTrackingCookieSet = ref(null);
  const shouldShowCookieModal = computed(
    () => isTrackingCookieSet.value === false && !urlInfo.value.hide_cookiewall
  );

  const startTracking = () => {
    if (process.server) return;
    window.exponea?.start?.();
  };

  const getMarketingConsentCookie = () => {
    return $cookies.get(MARKETING_CONSENT_COOKIE);
  };

  const getAnalyticsConsentCookie = () => {
    return $cookies.get(ANALYTICS_CONSENT_COOKIE);
  };

  const getNovuloTrackingCookie = () => {
    return $cookies.get(NOVULO_TRACKING_COOKIE);
  };

  const setMarketingConsentCookie = (value) => {
    $cookies.set(MARKETING_CONSENT_COOKIE, value, {
      path: '/',
      // 400 days
      maxAge: 60 * 60 * 24 * 400
    });
    if (value) {
      $cookies.set(NOVULO_TRACKING_COOKIE, value, {
        path: '/',
        // 400 days
        maxAge: 60 * 60 * 24 * 400
      });
    }
  };

  const setAnalyticsConsentCookie = (value) => {
    $cookies.set(ANALYTICS_CONSENT_COOKIE, value, {
      path: '/',
      // 400 days
      maxAge: 60 * 60 * 24 * 400
    });
  };

  onMounted(() => {
    isTrackingCookieSet.value = getMarketingConsentCookie() !== undefined;
    const analyticalConsentCookie = getAnalyticsConsentCookie();
    if (isTrackingCookieSet.value && analyticalConsentCookie === undefined) {
      setAnalyticsConsentCookie(true);
    }
    if (getNovuloTrackingCookie()) {
      startTracking();
    }
  });

  return {
    shouldShowCookieModal,
    startTracking,
    getMarketingConsentCookie,
    getNovuloTrackingCookie,
    setMarketingConsentCookie,
    getAnalyticsConsentCookie,
    setAnalyticsConsentCookie
  };
}
