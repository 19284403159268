
































































































import { defineComponent, ref } from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';
/* @ts-ignore */
import careplanLogo from '@/assets/images/careplan.svg';
/* @ts-ignore */
import recycleLogo from '@/assets/images/recycle.svg';
/* @ts-ignore */
import sustainableLogo from '@/assets/images/sustainable.svg';

import { useFooter } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'FooterQuickLinks',
  setup() {
    const { isDesktop } = useWindowResize();
    const { footerResult } = useFooter();

    const openAccordionId = ref<number | null>(null);

    const toggleAccordion = (id: number) => {
      if (openAccordionId.value === id) {
        openAccordionId.value = null;
      } else {
        openAccordionId.value = id;
      }
    };

    const hasLogo = (link: any) => {
      return ['careplan', 'inruilen', 'sustainable_click'].includes(
        link.code_english
      );
    };
    const getLogo = (link: any) => {
      if (link.code_english === 'careplan') {
        return careplanLogo;
      }
      if (link.code_english === 'inruilen') {
        return recycleLogo;
      }
      if (link.code_english === 'sustainable_click') {
        return sustainableLogo;
      }
    };

    return {
      isDesktop,
      quickLinks: footerResult.value.footer_quicklinks,
      openAccordionId,
      toggleAccordion,
      hasLogo,
      getLogo
    };
  }
});
