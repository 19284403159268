










































































































































import { useContext } from '@nuxtjs/composition-api';
import useUrlFormatter from '~/hooks/useUrlFormatter';

export default {
  name: 'MegaMenuLevelTwo',
  props: {
    levelOne: {
      type: Object,
      default: () => ({})
    },
    activeTab: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { $config } = useContext();
    const formatUrl = useUrlFormatter();

    const getDiscoverMoreUrl = (tabData) => {
      const TABS_URLS_KEYS = {
        inspiratie: 'inspirationUrl',
        merken: 'brandUrl',
        producten: 'productsUrl'
      };
      const activeTabKey = TABS_URLS_KEYS[props.activeTab as string];
      const url = tabData.discoverMore[activeTabKey];
      return formatUrl(url);
    };

    const mediaUrl = (path: string): string => {
      return `${$config.mediaBaseURL}${path}`;
    };

    return {
      formatUrl,
      mediaUrl,
      getDiscoverMoreUrl
    };
  }
};
