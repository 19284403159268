

































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useFooter } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'FooterRating',
  setup() {
    const { footerResult } = useFooter();

    const reviews = computed(() =>
      footerResult.value.footer_rating.filter(
        (review) =>
          !['write_review', 'win_back_purchase_amount'].includes(
            (review as { code_english: string }).code_english
          )
      )
    );
    const winBackPurshase = computed(
      () =>
        footerResult.value.footer_rating.filter(
          (review) =>
            (review as { code_english: string }).code_english.toLowerCase() ===
            'win_back_purchase_amount'
        )[0] ?? null
    );
    const handleRedirect = (url: string) => {
      window.open(url);
    };

    return {
      footerResult,
      reviews,
      subscribeTitle: footerResult.value.footer_review.title,
      subscribeUrl: footerResult.value.footer_review.url as string,
      winBackPurshase,
      handleRedirect
    };
  }
});
