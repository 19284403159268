


























import { defineComponent } from '@nuxtjs/composition-api';
import { mediaUrlFallback } from '@/helpers/utils';
import { useFooter, useTranslations } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'BusinessLogos',
  props: {
    grayscale: {
      type: Boolean,
      default: false
    },
    logoHeight: {
      type: String,
      default: '48px'
    },
    showText: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { footerResult } = useFooter();
    const { translate } = useTranslations();

    const mediaUrl = (path: string): string => {
      return mediaUrlFallback(path);
    };

    return {
      mediaUrl,
      translate,
      logos: footerResult.value?.footer_business_logos
    };
  }
});
