






























import { defineComponent } from '@nuxtjs/composition-api';
import { useFooter } from '@vue-storefront/novulo';
import { mediaUrlFallback } from '@/helpers/utils';

export default defineComponent({
  name: 'FooterReviews',
  setup() {
    const { footerResult } = useFooter();

    const mediaUrl = (path: string): string => {
      return mediaUrlFallback(path);
    };

    return {
      links: footerResult.value?.footer_reviews || [],
      mediaUrl
    };
  }
});
