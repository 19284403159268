import { render, staticRenderFns } from "./FooterQuickLinks.vue?vue&type=template&id=9fe9449a&scoped=true&"
import script from "./FooterQuickLinks.vue?vue&type=script&lang=ts&"
export * from "./FooterQuickLinks.vue?vue&type=script&lang=ts&"
import style0 from "./FooterQuickLinks.vue?vue&type=style&index=0&id=9fe9449a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fe9449a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsTextLink: require('/var/www/packages/theme/components/atoms/TextLink.vue').default,AtomsAccordion: require('/var/www/packages/theme/components/atoms/Accordion.vue').default,MoleculesFooterRating: require('/var/www/packages/theme/components/molecules/FooterRating.vue').default,MoleculesFooterReviews: require('/var/www/packages/theme/components/molecules/FooterReviews.vue').default,MoleculesSocialLinks: require('/var/www/packages/theme/components/molecules/SocialLinks.vue').default})
